import * as React from 'react';
import News from './node-blocks/News';
import Award from './node-blocks/Award';
import {IImage} from "../interfaces/IImage";

interface IProps {
    node: {
        type: string;
        fields: {
            image: any,
            title: string,
            teaser: string
            image__gatsby_file: IImage,
            publish_date: string,
        }
    }
}

const Node = ({node,}: IProps) => {
    switch (node.type) {
        case 'news':
            return <section className="node-block node-block--news section-0 section--hero">
                <News fields={node.fields} />
            </section>;
        case 'award':
            return <section className="node-block node-block--award section-0 section--hero">
                <Award node={node} />
            </section>;
        default:
            return <></>;
    }

};

export default Node;
