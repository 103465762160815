import * as React from "react"
import Layout from "../components/Layout";
import ContentBlocksLegal from "../components/ContentBlocksLegal";
import {graphql} from "gatsby";
import Helmet from 'react-helmet';

export const query = graphql`
query nodeImpressum($id: String) { 
        pages: allMythNode(filter: {id: {eq: $id}}) {
            edges {
                node {
                    id
                    type
                    url
                    fields: nodeFields {
                      title
                      image {
                        alt
                      }
                      image__gatsby_file {
                        extension
                        url
                        childImageSharp {
                          gatsbyImageData
                        }
                      }
                      publish_date
                    }
                    contentBlocks: children {
                      ... on MythContentBlock {
                        id
                        section
                        content_block_name
                        mythFields {
                            hero__images_1 {
                                url
                                alt
                            }
                            hero__images_1__gatsby_file {
                                childImageSharp {
                                    gatsbyImageData
                                }
                            }
                            splash_image {
                                url
                                alt
                            }
                            splash_image__gatsby_file {
                                childImageSharp {
                                    gatsbyImageData
                                }
                            }
                            banner__claim
                            style
                            banner__logo {
                              alt
                            }
                            banner__logo__gatsby_file {
                              extension
                              url
                              childImageSharp {
                                gatsbyImageData
                              }
                            }
                            gallery__layout
                            gallery__reference
                            textpic__headline
                            textpic__headline__tagname
                            textpic__content
                            textpic__layout
                            textpic__url
                            textpic__image {
                              alt
                            }
                            image: textpic__image__gatsby_file {
                              extension
                              url
                              childImageSharp {
                                gatsbyImageData(aspectRatio: 1)
                              }
                            }
                            text__headline
                            text__headline__tagname
                            headline_style
                            headline_layout
                            content
                            style   
                            url  
                            ratio
                            layout
                            person_name
                            voting__reference
                        }
                      }
                    }
                }
            }
        }
        }
  `;

const MythNodePage = ({pageContext, data}) => {
    const node = data.pages.edges[0].node;
    return <Layout>
        <Helmet>
            <title>Impressum | NEW FACES AWARD</title>
        </Helmet>
        <ContentBlocksLegal pageContext={pageContext} node={node}/>
    </Layout>
}
export default MythNodePage;

