import * as React from "react"
import {IContentBlockAbstract} from "../interfaces/IContentBlockAbstract";
import classNames from "../helpers/classNames";
import {camelCaseToSlug} from "../helpers/strings";
import {graphql, StaticQuery} from "gatsby";
import Headline from "./content-blocks/Headline";
import {IContentBlockHeadline} from "../interfaces/IContentBlockHeadline";
import Hero from "./content-blocks/Hero";
import {IContentBlockHero} from "../interfaces/IContentBlockHero";
import Banner from "./content-blocks/Banner";
import {IContentBlockBanner} from "../interfaces/IContentBlockBanner";
import Text from "./content-blocks/Text";
import {IContentBlockText} from "../interfaces/IContentBlockText";
import TextPic from "./content-blocks/TextPic";
import {IContentBlockTextPic} from "../interfaces/IContentBlockTextPic";
import NewsList from "./content-blocks/NewsList";
import PartnersList from "./content-blocks/PartnersList";
import TextVideo from "./content-blocks/TextVideo";
import {IContentBlockTextVideo} from "../interfaces/IContentBlockTextVideo";
import WallOfFame from "./content-blocks/WallOfFame";
import {IContentBlockWallOfFame} from "../interfaces/IContentBlockWallOfFame";
import Gallery from "./content-blocks/Gallery";
import {IContentBlockGallery} from "../interfaces/IContentBlockGallery";
import NodeContent from "./NodeContent";

const ContentBlocksLegal = ({pageContext, node}) => {
    const sections: { [index: string]: IContentBlockAbstract[] } = {};
    for (const contentBlock of node.contentBlocks) {
        if (!sections[contentBlock.section]) {
            sections[contentBlock.section] = [];
        }
        sections[contentBlock.section].push(contentBlock);
    }
    return <>
        <NodeContent node={node} />
        {
            Object.keys(sections).map((sectionKey: string) => {
                return <section
                    key={`section-${sectionKey}`}
                    className={classNames({
                        [`section-${sectionKey}`]: true,
                        'section--first': '1' === sectionKey,
                        'section--last': Object.keys(sections).length === parseInt(sectionKey),
                        'section--hero': 'hero' === sections[sectionKey][0]['content_block_name'] && '1' === sectionKey,
                    })}
                >{
                    sections[sectionKey].map((contentBlock: IContentBlockAbstract, index: number) => {
                        const classes = ['content-block', `content-block-legal--${camelCaseToSlug(contentBlock.content_block_name)}`,];
                        return <div className={classes.join(' ')} key={index}>
                            {'hero' === contentBlock.content_block_name &&
                            <Hero contentBlock={contentBlock as IContentBlockHero}/>}
                            {'text' === contentBlock.content_block_name &&
                            <Text layout="legal" layout2="even" contentBlock={contentBlock as IContentBlockText}/>}
                        </div>;

                    })
                }</section>;
            })}
    </>
}
export default ContentBlocksLegal;

