import * as React from 'react';
import BackButton from '../BackButton';
import {IImage as IGatsbyImage} from "gatsby-plugin-image";
import {IImage} from "../../interfaces/IImage";
import Media from "../Media";
import {MediaExtensions} from "../../typescript/types";

interface IAward {
    node: {
        fields: {
            title: string,
            image: IImage,
            image__gatsby_file: {
                extension: MediaExtensions,
                url: string,
                childImageSharp: {
                    gatsbyImageData: IGatsbyImage
                }
            },
        };
    }
}

const Award = ({node,}: IAward) => {
    return <article>
        <BackButton className={'back-link'} prependArrow={true} />
        <div className={'content-block content-block--hero'}>
            <div>
                <Media
                    alt={node.fields.image?.alt}
                    file={node.fields.image__gatsby_file}
                    type="image"
                />
                <header>
                    <h1>{node.fields.title}</h1>
                </header>
            </div>
        </div>
    </article>;

};

export default Award;
