import * as React from 'react';
import BackButton from '../BackButton';
import Media from "../Media";
import {IImage} from "../../interfaces/IImage";

interface INews {
    fields: {
        image: any,
        image__gatsby_file: IImage,
        title: string,
        teaser: string
        publish_date: string,
    };
}

const News = ({fields,}: INews) => {
    return <article>
        <BackButton className={'back-link'} prependArrow={true} />
        <div className={'content-block content-block--hero'}>
            <div>
                {
                    fields.image && fields.image.length && fields.image[0] &&
                    <Media
                        alt={fields.image[0].alt}
                        file={fields.image__gatsby_file}
                        type="image"/>

                }
                <header>
                    <time dateTime="2017-10-30" itemProp="datePublished">{fields.publish_date}</time>
                    <h1>{fields.title}</h1>
                </header>
            </div>
        </div>
        {/*TODO: Remove this*/}
        <div className={'content-block content-block--text content-block--text--rose'} style={{height: 0, padding: 0}}>
            {/*<div>*/}
            {/*    <p>*/}
            {/*        {fields.teaser}*/}
            {/*    </p>*/}
            {/*</div>*/}
        </div>
    </article>;

};

export default News;
